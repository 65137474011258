import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { departmentsColors } from "../../utils/department";
import { useSelector } from "react-redux";

const darkenColor = (color, percent) => {
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  r = Math.max(0, r - (r * percent) / 100);
  g = Math.max(0, g - (g * percent) / 100);
  b = Math.max(0, b - (b * percent) / 100);

  return `rgb(${r}, ${g}, ${b})`;
};

// const users = [
//   {
//     name: "Marketing",
//     events: [
//       { title: "Team Meeting", startHour: 9, endHour: 19, color: "#4caf50" },
//       {
//         title: "Review Campaign",
//         startHour: 8,
//         endHour: 22,
//         color: "#ff9800",
//       },
//       {
//         title: "Review Campaign",
//         startHour: 9,
//         endHour: 21,
//         color: "#ff9800",
//       },
//     ],
//   },
//   {
//     name: "Development",
//     events: [
//       { title: "Code Review", startHour: 12, endHour: 13, color: "#03a9f4" },
//       {
//         title: "Project Meeting",
//         startHour: 15,
//         endHour: 17,
//         color: "#e91e63",
//       },
//       {
//         title: "Review Campaign",
//         startHour: 12,
//         endHour: 17,
//         color: "#ff9800",
//       },
//       {
//         title: "Review Campaign",
//         startHour: 10,
//         endHour: 16,
//         color: "#ff9800",
//       },
//     ],
//   },
//   {
//     name: "Design",
//     events: [
//       { title: "Brainstorming", startHour: 9, endHour: 11, color: "#9c27b0" },
//       { title: "Prototyping", startHour: 13, endHour: 15, color: "#ffc107" },
//     ],
//   },
// ];

// Generate array of time intervals for 24 hours (starting at 8 AM)
const hours = Array.from({ length: 15 }, (_, i) => (i + 8) % 24);

const formatHour = (hour) => {
  const suffix = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:00 ${suffix}`;
};

const OfficeTimeline = (props) => {
  const { activeView, classes } = props;
  const containerRef = useRef(null);
  const [chartViewData, setChartView] = useState([]);
  const coverage = useSelector((item) => item.Staff.coverage);

  useEffect(() => {
    if (coverage?.data?.currentCoverage?.length) {
      if (activeView === "departments") {
        let chartData = [];
        coverage?.data?.currentCoverage?.map((cov) => {
          let events = [];
          cov?.onduty_staff_departments?.map((dept) => {
            dept?.user?.schedule?.map((sch) => {
              events?.push({
                title: cov.name,
                startHour: Number(sch.start_time?.slice(0, 2)),
                endHour: Number(sch.end_time?.slice(0, 2)),
                color:
                  departmentsColors?.find((it) => it.name === cov.name)
                    ?.backgroundColor || "#DDC441CC",
              });
            });
          });
          chartData.push({ name: cov.name, events });
        });
        setChartView(chartData);
      } else {
        let chartData = [];
        coverage?.data?.currentCoverage?.map((cov) => {
          let events = [];
          cov?.onduty_staff_departments?.map((dept) => {
            dept?.user?.schedule?.map((sch) => {
              events?.push({
                title: dept?.user?.full_name,
                startHour: Number(sch.start_time?.slice(0, 2)),
                endHour: Number(sch.end_time?.slice(0, 2)),
                color:
                  departmentsColors?.find((it) => it.name === cov.name)
                    ?.backgroundColor || "#DDC441CC",
              });
            });
          });
          chartData.push({ name: cov.name, events });
        });
        setChartView(chartData);
      }
    }
  }, [activeView, coverage?.data?.currentCoverage?.length]);
  return (
    <Box ref={containerRef} sx={{ position: "relative" }}>
      <Box
        sx={{
          overflowY: "hidden",
          overflowX: "auto",
        }}
      >
        <Box className={classes.timeChangeSection}>
          <Box className={classes.timeChangeSectionTop}>
            {hours?.slice(0, 15).map((hour) => (
              <Box className={classes.timeChangeSectionTopItem}>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "left", ml: "-20px" }}
                >
                  {formatHour(hour)}
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    position: "absolute",
                    left: "0%",
                    top: "30px",
                    bottom: 0,
                    borderLeft: "1px solid #ddd",
                    height: `100vh`, // Dynamic height based on the container
                  }}
                />
              </Box>
            ))}
          </Box>
          {chartViewData.map((user, userIndex) => (
            <Box
              key={userIndex}
              sx={{
                mt: activeView === "individual" ? 2 : 1,
                position: "relative",
                height: `${user.events?.length * 16}px`,
              }}
            >
              {/* User timeline: Display events */}
              {user.events?.map((event, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    left: `${(event.startHour - 8) * 91}px`,
                    width: `${(event.endHour - event.startHour) * 91}px`,
                    height: "12px",
                    backgroundColor: event.color,
                    "&:hover": {
                      backgroundColor: darkenColor(event.color, 20),
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    boxShadow: 1,
                    transition: "width 1.5s ease", // Transition for width
                    top: `${
                      (index + 1) * (activeView === "individual" ? 16 : 12)
                    }px`,
                    cursor: "pointer",
                    zIndex: 999,
                  }}
                  title={event.title}
                >
                  <Typography
                    title={event.title}
                    variant="body2"
                    color="white"
                  ></Typography>
                </Box>
              ))}
            </Box>
          ))}
          <Box className={classes.paddingSection}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OfficeTimeline;
