import React, { Suspense } from "react";
import PropTypes from "prop-types";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigate, useLocation, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import TopNavBar from "../topNavBar";
import Backdrop from "@mui/material/Backdrop";
import navLogo from "../../assets/images/header/navLogo.png";
import SideNavBar from "../sideNavBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import UnauthorizedPage from "../unAuthorised";
import Drawer from "@mui/material/Drawer";
import { AppContextProvider } from "../callPanel/callContext";
import { Divider } from "@mui/material";
import logo2 from "../../assets/images/header/logo2.png";
import nhgShortLogo from "../../assets/images/nhgShortLogo.png"
import nhgFullLogo from "../../assets/images/nhgFullLogo.png"
import MainLoader from "../loader/mainLoader";
import Footer from "../footer";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SidebarContext from "./sidebarContext";
import MainDashBoardSkelton from "../../container/mainDashboard/skeltons/mainSkelton";
const drawerWidth = 256;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    background: "white",
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
function PrivateRoutes({ children, type, ...rest }) {
  const { window } = rest;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pcOpen, setPcOpen] = React.useState(true);
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpenPc = () => {
    setPcOpen(true);
  };

  React.useEffect(() => {
    if (matches) {
      setPcOpen(false);
    }
  }, [matches]);

  const handleDrawerClosePc = () => {
    setPcOpen(false);
  };

  const adminAuth = useSelector((state) => state.adminAuth);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  if (localStorage.token) {
    return (
      <Box sx={{ display: "flex" }} className="auth-app-bar">
        <AppContextProvider>
          <CssBaseline />
          <AppBar
            position="fixed"
            color="default"
            enableColorOnDark
            evaluation={0}
            sx={{
              width: { sm: `calc(100% - ${pcOpen ? drawerWidth : 60}px)` },
              ml: { sm: `${pcOpen ? drawerWidth : 60}px` },
              boxShadow: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              zIndex: "1",
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <TopNavBar />
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{
              width: { sm: pcOpen ? drawerWidth : 60 },
              flexShrink: { sm: 0 },
            }}
            aria-label="mailbox folders"
          >
            {/* <SideNavBar /> */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  overflow: "visible",
                  width: drawerWidth,
                  zIndex: "1000",
                },
              }}
            >
              <DrawerHeader>
                <Toolbar>
                  <img
                    style={{ marginTop: "70px" }}
                    src={process.env.REACT_APP_SITE==="NHG"?nhgShortLogo:logo2}
                    alt="Nav logo"
                  />
                </Toolbar>
                <Divider />
              </DrawerHeader>
              <SideNavBar />
            </Drawer>
            <Drawer
              variant="permanent"
              open={pcOpen}
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: pcOpen ? drawerWidth : 60,
                  boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                  filter:
                    "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))",
                  borderRight: "none",
                  zIndex: "1000",
                  overflow: "visible",
                },
              }}
            >
              <DrawerHeader style={{ marginRight: "-1px" }}>
                <Toolbar style={{ paddingRight: pcOpen ? "" : "7px" }}>
                  {pcOpen ? (
                    <img
                      style={{ width: "180px", marginRight: "-10px" }}
                      src={process.env.REACT_APP_SITE==="NHG"?nhgFullLogo:navLogo}
                      alt="Nav logo"
                    />
                  ) : (
                    <img
                      onClick={handleDrawerOpenPc}
                      style={{ width: "30px", cursor: "pointer" }}
                      src={process.env.REACT_APP_SITE==="NHG"?nhgShortLogo:logo2}
                      alt="Nav logo"
                    />
                  )}
                </Toolbar>
                {pcOpen && (
                  <IconButton onClick={handleDrawerClosePc}>
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                )}
              </DrawerHeader>
              <SideNavBar pcOpen={pcOpen} />
            </Drawer>
          </Box>
          <SidebarContext.Provider value={pcOpen}>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                position: "relative",
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                backgroundColor: "#fafafa",
                /*minHeight: "100vh",*/
                padding: "0 0 !important",
                paddingLeft: "0px",
                paddingRight: "0px",
                marginTop: "64px",
                minWidth: "1200px",
              }}
              id="main-section"
            >
              {adminAuth.isLoading ? (
                <div>
                  <MainDashBoardSkelton />
                </div>
              ) : adminAuth.adminDetail?.permissions?.find((item) =>
                  location.pathname.split("/").includes(item)
                ) ||
                location.pathname === "/teams" ||
                location.pathname === "/" ||
                type === "404" ? (
                <Box sx={{ position: "sticky" }}>
                  {children}
                  <Footer {...rest} />
                </Box>
              ) : (
                <UnauthorizedPage />
              )}
            </Box>
          </SidebarContext.Provider>

          {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={adminAuth.isLoading}>
            <MainLoader />
          </Backdrop> */}
        </AppContextProvider>
      </Box>
    );
  } else {
    return <Navigate /* replace  */ to={`/login`} />;
  }
}

PrivateRoutes.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withStyles(styleSheet, { name: "PrivateRoutesStyle" })(
  PrivateRoutes
);
