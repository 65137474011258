import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { DoNotDisturb } from "@mui/icons-material";
import UpdateIcon from "@mui/icons-material/Update";
import {
  clockOutStaff,
  clockInStaff,
} from "../../redux/common/userStaffDepartment";
import AvatarComponent from "../../container/projects/AvatarComponent";
import { useSelector, useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { StyledBadge } from "./index";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  departmentBox: {
    minWidth: 270,
    position: "absolute",
    right: 225,
    minHeight: 300,
    maxHeight: "790px",
    top: 0,
  },
  departmentBoxContent: {
    padding: "0px!important",
    paddingBottom: "0px!important",
  },
  departmentListing: {
    minHeight: 250,
    padding: "10px 2px 0px 10px",
    maxHeight: "470px !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
  },
  listItem: {
    margin: "0px!important",
    padding: "0px!important",
    fontSize: "14px!important",
    fontFamily: "AvenirNext !important",
    marginBottom: "8px!important",
  },
  departmentName: {
    fontSize: "14px!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
  },
  listItemUsers: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "27px",
    marginLeft: "6px",
  },
  listItemUsersLead: {
    // paddingRight: "10px",
  },
  listItemUsersSupport: {
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  updatePositionButton: {
    width: "24px !important",
    minWidth: "24px !important",
    height: "24px !important",
  },
  updatePositionButtonBlack: {
    border: "1px solid #000000 !important",
    borderRadius: "5px !important",
    color: "#000 !important",
  },
  updatePositionButtonRed: {
    border: "1px solid #C60C0C !important",
    borderRadius: "5px !important",
    color: "#C60C0C !important",
  },
  clockOutButton: {
    width: "100%!important",
    borderColor: "rgba(0, 0, 0, 0.6)!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.6)!important",
    },
    "&:disabled": {
      color: "#ddd!important",
      borderColor: "#ddd!important",
    },
    fontSize: "15px!important",
  },
  clockInButton: {
    width: "100%!important",
    borderColor: "rgba(0, 0, 0, 0.6)!important",
    color: "#0B0909!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.6)!important",
    },
    "&:disabled": {
      color: "#ddd!important",
      borderColor: "#ddd!important",
    },
    fontSize: "15px!important",
  },
  clockOutButtonIcon: {
    fontSize: "24px!important",
    paddingRight: "5px!important",
    paddingBottom: "5px",
    color: "red!important",
  },
  clockInButtonIcon: {
    fontSize: "24px!important",
    paddingRight: "5px!important",
    paddingBottom: "5px",
    color: "green!important",
  },
}));

function StaffWidget(props) {
  const classes = useStyles();
  const { adminAuth } = props;
  const [onDutyDepartmentsState, setOnDutyDepartmentsState] = React.useState(
    []
  );
  const dispatch = useDispatch();
  const onDutyUsers = useSelector(
    (state) => state.userStaffDepartment.onDutyUsers
  );
  const onDutyDepartments = useSelector(
    (state) => state.userStaffDepartment.onDutyDepartments
  );

  const clockingOutLoading = useSelector(
    (state) => state.userStaffDepartment.clockingOutLoading
  );
  const clockingInLoading = useSelector(
    (state) => state.userStaffDepartment.clockingInLoading
  );

  const clockIn = () => {
    dispatch(clockInStaff({}));
  };

  const doesCurrentUserHavePositions = () => {
    let flag = false;
    onDutyUsers.forEach((user) => {
      let foundUser = user.id === adminAuth?.adminDetail?.id;
      if (foundUser && user?.onduty_staff_departments?.length > 0) {
        flag = true;
      }
    });
    return flag;
  };

  const getOnDutyLeadUserDetails = (departmentID) => {
    let foundUser;
    onDutyUsers.forEach((user) => {
      let found = user.onduty_staff_departments.find(
        (dep) => dep.department_id === departmentID && dep.as === "lead"
      );
      if (found) foundUser = user;
    });

    return foundUser;
  };

  const getOnDutySupportUsers = (departmentID) => {
    let foundUsers = [];
    let isCurrentUserinSupportAlready = false;
    onDutyUsers.forEach((user) => {
      let found = user.onduty_staff_departments.find(
        (dep) => dep.department_id === departmentID && dep.as === "support"
      );
      if (found) {
        foundUsers.push(user);

        if (found.user_id === adminAuth?.adminDetail?.id) {
          isCurrentUserinSupportAlready = true;
        }
      }
    });

    return { supportUsers: foundUsers, isCurrentUserinSupportAlready };
  };

  return (
    <Card className={classes.departmentBox}>
      <CardContent className={classes.departmentBoxContent}>
        <Typography
          sx={{
            fontSize: "17px!important",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
          variant="h6"
        >
          Currently on duty:
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "4px 0px",
            padding: "0px 10px",
            flexWrap: "wrap",
          }}
        >
          {onDutyUsers.map((user) => {
            const isActive = user.twilio_call_extension === null;
            const status = (
              user.twilio_call_extension?.status || ""
            ).toLowerCase();

            return (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                  color: "#2E7D32",
                }}
                variant={"dot"}
                color={isActive ? "success" : "error"}
                customColor={
                  isActive || status === "available"
                    ? "#2E7D32"
                    : status === "busy"
                    ? "#F3B30E"
                    : "rgba(219, 10, 10, 0.6)"
                }
              >
                <AvatarComponent
                  sx={{
                    width: "32px!important",
                    height: "32px!important",
                    borderRadius: "6px!important",
                  }}
                  src={user?.profile_images?.profile_img_thumbnail || null}
                  nameInitial={user?.full_name}
                  title={user?.full_name}
                />
              </StyledBadge>
            );
          })}
        </Box>

        <Box className={classes.departmentListing}>
          {onDutyDepartments?.length
            ? onDutyDepartments.map((item, index) => {
                const leadUserDetails = getOnDutyLeadUserDetails(
                  item?.department?.id
                );
                const { supportUsers, isCurrentUserinSupportAlready } =
                  getOnDutySupportUsers(item?.department.id);
                const isCurrentUserinLeadAlready =
                  leadUserDetails?.id === adminAuth?.adminDetail?.id;

                return (
                  <Box key={item.id} className={classes.listItem}>
                    <Typography variant="h6" className={classes.departmentName}>
                      {item?.department?.name}
                    </Typography>

                    <Box className={classes.listItemUsers}>
                      {isCurrentUserinLeadAlready ||
                      (!isCurrentUserinLeadAlready && item.as === "lead") ||
                      (leadUserDetails &&
                        leadUserDetails?.id !== adminAuth?.adminDetail?.id) ? (
                        <Box
                          className={classes.listItemUsersLead}
                          sx={{
                            borderRight:
                              supportUsers?.length > 1
                                ? "1px solid #000000"
                                : "",
                          }}
                        >
                          {isCurrentUserinLeadAlready ? (
                            <AvatarComponent
                              src={adminAuth?.adminDetail?.profile_img || null}
                              sx={{
                                width: "24px!important",
                                height: "24px!important",
                                borderRadius: "5px!important",
                              }}
                              nameInitial={adminAuth?.adminDetail?.full_name}
                            />
                          ) : !isCurrentUserinLeadAlready &&
                            item.as === "lead" &&
                            adminAuth?.adminDetail ? (
                            <Box
                              onMouseEnter={() => {
                                let updatedList = [...onDutyDepartmentsState];
                                updatedList = updatedList.map((ul) => {
                                  if (ul.id === item?.department.id) {
                                    ul.showDelete = true;
                                  }
                                  return ul;
                                });
                                setOnDutyDepartmentsState(updatedList);
                              }}
                              onMouseLeave={() => {
                                let updatedList = [...onDutyDepartmentsState];
                                updatedList = updatedList.map((ul) => {
                                  if (ul.id === item?.department.id) {
                                    ul.showDelete = false;
                                  }
                                  return ul;
                                });
                                setOnDutyDepartmentsState(updatedList);
                              }}
                              sx={{ position: "relative" }}
                            >
                              <AvatarComponent
                                src={
                                  adminAuth?.adminDetail?.profile_img || null
                                }
                                sx={{
                                  width: "24px!important",
                                  height: "24px!important",
                                  borderRadius: "5px!important",
                                }}
                                nameInitial={adminAuth?.adminDetail?.full_name}
                              />

                              {!item.showDelete && (
                                <CheckCircleIcon
                                  sx={{
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-6px",
                                    fontSize: "12px",
                                    color: "green",
                                  }}
                                />
                              )}
                            </Box> // lead position editable if current user is in lead
                          ) : leadUserDetails &&
                            leadUserDetails?.id !==
                              adminAuth?.adminDetail?.id ? (
                            <AvatarComponent
                              src={
                                leadUserDetails?.profile_images
                                  ?.profile_img_thumbnail || null
                              }
                              sx={{
                                width: "24px!important",
                                height: "24px!important",
                                borderRadius: "5px!important",
                              }}
                              title={leadUserDetails?.full_name}
                              nameInitial={leadUserDetails?.full_name}
                            /> // lead position not editable if current user is not in lead
                          ) : null}
                        </Box>
                      ) : null}

                      <Box className={classes.listItemUsersSupport}>
                        {supportUsers.map((supportUser) => (
                          <AvatarComponent
                            src={
                              supportUser?.profile_images
                                ?.profile_img_thumbnail || null
                            }
                            sx={{
                              width: "24px!important",
                              height: "24px!important",
                              borderRadius: "5px!important",
                            }}
                            title={supportUser?.full_name}
                            nameInitial={supportUser?.full_name}
                          />
                        ))}

                        {!isCurrentUserinSupportAlready &&
                          item.as === "support" &&
                          adminAuth?.adminDetail && (
                            <Box
                              onMouseEnter={() => {
                                let updatedList = [...onDutyDepartmentsState];
                                updatedList = updatedList.map((ul) => {
                                  if (ul.id === item?.department.id) {
                                    ul.showDelete = true;
                                  }
                                  return ul;
                                });
                                setOnDutyDepartmentsState(updatedList);
                              }}
                              onMouseLeave={() => {
                                let updatedList = [...onDutyDepartmentsState];
                                updatedList = updatedList.map((ul) => {
                                  if (ul.id === item?.department.id) {
                                    ul.showDelete = false;
                                  }
                                  return ul;
                                });
                                setOnDutyDepartmentsState(updatedList);
                              }}
                              sx={{ position: "relative" }}
                            >
                              <AvatarComponent
                                src={
                                  adminAuth?.adminDetail?.profile_img || null
                                }
                                sx={{
                                  width: "24px!important",
                                  height: "24px!important",
                                  borderRadius: "5px!important",
                                }}
                                nameInitial={adminAuth?.adminDetail?.full_name}
                              />

                              {!item.showDelete && (
                                <CheckCircleIcon
                                  sx={{
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-6px",
                                    fontSize: "12px",
                                    color: "green",
                                  }}
                                />
                              )}

                              {item.showDelete && (
                                <RemoveCircleIcon
                                  sx={{
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-6px",
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                />
                              )}
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            : null}
        </Box>
        <Grid container item xs={12} justifyContent="flex-end" p={1}>
          {doesCurrentUserHavePositions() ? (
            <Button
              onClick={() => {
                dispatch(clockOutStaff());
              }}
              className={classes.clockOutButton}
              variant="outlined"
              disabled={clockingOutLoading}
            >
              <DoNotDisturb className={classes.clockOutButtonIcon} />{" "}
              {clockingOutLoading ? "CLOCKING OUT.." : "CLOCK OUT"}
            </Button>
          ) : (
            <Button
              disabled={clockingInLoading}
              onClick={clockIn}
              className={classes.clockInButton}
              variant="outlined"
            >
              <UpdateIcon className={classes.clockInButtonIcon} />{" "}
              {clockingInLoading ? "CLOCKING IN.." : "CLOCK IN"}
            </Button>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default StaffWidget;
