import "./style.css";
import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import NameHeaderCard from "./cards/NameHeaderCard";
import { useDispatch, useSelector } from "react-redux";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Route, Routes, useParams } from "react-router-dom";
import AgentRosterDetailsTab from "./tabsContent/userDetailsTab";
import { getAgentRosterProfile } from "../../../redux/agents/agentRoster/getAgentRosterProfile";
import AgentRosterPaymentTab from "../../agentRoster/agentDetails/tabsContent/agentRosterPaymentTab";
import AgentTrainingTab from "./tabsContent/TrainingTab";
import AgentInventoryTab from "./tabsContent/inventoryTab";
import UserMainSkelton from "../skeltons";
import AgentPoints from "../../agentRoster/agentDetails/tabsContent/points";
import PointsLeaderBoards from "../../agentRoster/agentDetails/tabsContent/leaderBoards";
function AgentRosterDetails({ classes }) {
  const { id } = useParams();
  const data = useSelector((item) => item.agentRoster.agentRosterProfile);
  const { loading, profile } = data;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAgentRosterProfile({ id: id }));
  }, [id]);
  return (
    <Container maxWidth="xl">
      <div className={classes.pageRoot}>
        {loading ? (
          <UserMainSkelton />
        ) : (
          <Box>
            <NameHeaderCard {...profile} id={id} />
            <Routes>
              <Route path="/details" element={<AgentRosterDetailsTab />} />
              <Route path="/payments" element={<AgentRosterPaymentTab />} />
              <Route path="/training" element={<AgentTrainingTab />} />
              <Route path="/inventory" element={<AgentInventoryTab />} />
              <Route path="/points" element={<AgentPoints isUser />} />
              <Route
                path="/leaderboard"
                element={<PointsLeaderBoards isUser />}
              />
            </Routes>
          </Box>
        )}
      </div>
    </Container>
  );
}
export default withStyles(styleSheet, { name: "agentRosterProfileStyle" })(
  AgentRosterDetails
);
