const logo = require("../../assets/logo.png");
const nhgShortLogo = require("../../assets/images/nhgShortLogo.png");
let newLogo = process.env.REACT_APP_SITE === "NHG" ? nhgShortLogo : logo;
export const styleSheet = () => ({
  "@global": {
    ".login-page": {
      minHeight: "100vh",
      backgroundImage: "url(" + newLogo + ")",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundOrigin: "content-box",
    },
    "@media (max-width: 575px)": {
      ".login-page": {
        backgroundPosition: "bottom right",
        backgroundSize: "85%",
      },
    },
    "@media (min-width: 575px)": {
      ".login-page": {
        backgroundPosition: "top right",
        backgroundSize: "45%",
      },
    },
    ".login-page .login-form-area": {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
    },
    ".login-page .login-form-area .login-form-area-box": {
      marginTop: "20%",
      width: "270px",
    },
    ".login-page .login-form-area .login-form-area-box .form-group .form-group-element":
      {
        marginTop: "30px",
        fontSize: "14px",
      },
    ".login-page .login-form-area .login-form-area-box .form-group .form-group-element b":
      {
        cursor: "pointer",
        marginLeft: "7px",
      },
    ".login-page .login-form-area .login-form-area-box .form-group .form-group-element button":
      {
        width: "100%",
        height: "35px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        color: "white",
        borderRadius: "25px",
      },
  },
});
